module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/builds/johnVonTrapp/pitarresi-website/node_modules/@marinda/gatsby-theme-recipes/src/components/MarkdownPagesLayout.js","pages":"/builds/johnVonTrapp/pitarresi-website/node_modules/@marinda/gatsby-theme-recipes/src/components/MarkdownLayout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":510,"maxHeight":510,"linkImagesToOriginal":false,"withWebp":true,"quality":75}}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Classic Recipes","short_name":"Recipes","start_url":"/","background_color":"#FFF","theme_color":"#FFF","display":"standalone","icon":"/builds/johnVonTrapp/pitarresi-website/node_modules/@marinda/gatsby-theme-recipes/src/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"5ba6f3df7db6e689317d05b8a24925c5"},
    },{
      plugin: require('../node_modules/@marinda/gatsby-theme-recipes/gatsby-browser.js'),
      options: {"plugins":[],"title":"Classic Recipes","shortTitle":"Recipes"},
    }]
